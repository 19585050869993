import React, { Component } from 'react';
import WithAuthentication from '../context/WithAuthentication';
import Layout from '../components/layout';
import auth from '../utils/auth';
import axios from 'axios';
import Helmet from 'react-helmet';
const API = process.env.API;
class AccountPage extends Component {
  render() {
    return (
      <Layout>
        <Helmet title="My Account | New Image™ Head Office" htmlAttributes={{lang: 'en'}}/>
        <UserInfo/>
        <Feedback/>
      </Layout>
    );
  }
}

export default WithAuthentication(AccountPage);


export class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {}
    }
  }
  
  componentDidMount() {
    const token = auth.getToken();
    const userInfo = auth.getUserInfo();
    if(token){
      this.setState({userInfo})
    }

  }
  render() {
    const {username, email} = this.state.userInfo;
    
    return (
      <div className="container py-10 text-brand-blue">
        <h4 className="text-3xl mb-8">Account</h4>
        <strong>Name:</strong> {username}<br/>
        <PasswordReset email={email}/>
      </div>
    );
  }
}


export class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '', 
      success: '', 
      loading: false
    }
  }
  
  submitResetPasswordForm(e){
    const {email} = this.props;
    e.preventDefault();
    e.stopPropagation();
    this.setState({loading: true, success: '', error: ''})
    // Request API.
    try{
    axios
      .post(`${API}/auth/forgot-password`, {email})
      .then(() => {
        // Handle success.
        this.setState({success: `An email has been to ${email}`, loading: false, error: ''})
      })
      .catch(error => {
        // Handle error.
        this.setState({error: 'An error occurred', loading: false, success: ''})
      });
    }
    catch {
      this.setState({error: 'An unknown error occurred', success: '', loading: false})
    }
  }
  render() {
    const {email} = this.props; 
    const {success, error, loading} = this.state; 
    return (
      <div className="max-w-sm pt-8 block">
        <h3 className="text-3xl my-8">Reset password</h3>
        <form onSubmit={this.submitResetPasswordForm.bind(this)}>
          
          <label htmlFor="email" className="block mb-2 text-sm font-semibold">Email</label>
          <input value={email} className="border p-2 bg-gray-100 block w-full"/>
          <button className="bg-brand-blue text-white py-2 px-8 rounded-xl mt-4 whitespace-nowrap mb-8 flex items-center">
            <span>Reset Password</span> {loading&& <span className="ml-2 w-10 inline-block"><EllipsesLoader/></span>}
          </button>
          {error&&
            <div className="font-bold text-red-900 bg-red-100 p-2">
              {error}
            </div>
          }
          {success&&
            <div className="font-bold text-green-900 bg-green-100 p-2 text-sm inline-block">
              {success}
            </div>
          }
        </form>
      </div>
    );
  }
}

export const EllipsesLoader = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: 'auto', background: 'transparent', display: 'block'}} width="25px" height="25px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx={50} cy={50} fill="none" stroke="#fff" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
      </circle>
    </svg>


  )
}
export class Feedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: '',
      feedback: '',
      statusMessage: ''
    }
  }
  
  submitFeedbackForm(e){
    this.setState({loading: true})
    e.preventDefault();
    e.stopPropagation();
    const {feedback, rating} = this.state;
    const user = auth.getUserInfo();
    alert(user)
    console.log(user)
    const token = auth.getToken();
    axios({method: 'POST',
    url: `${API}/feedbacks`,
    data: {feedback, rating, user: user._id},
    headers: {
      Authorization: `Bearer ${token}`
    }})
    .then(()=>{
      this.setState({
        success: 'Thanks for your feedback!',
        error: '',
        loading: false,
        feedback: '',
      })
    }).catch((err)=>{this.setState({error: 'There was an error', loading: false,success: ''})})
    


  }
  render() {
    const {error, success, loading, feedback} = this.state;
    return (
      <div className="container pb-20 text-brand-blue">

        <h4 className="text-3xl mb-8 mt-8">Feedback</h4>
      <form onSubmit={this.submitFeedbackForm.bind(this)}>
        <label htmlFor="rating" className="text-sm font-semibold">Rating</label>

          <div className="flex w-full justify-items-center items-center mb-8">
            <div className="px-1 mx-2 text-2xl">
              <span aria-label="good" role="img">😀</span>
              <input required type="radio" className="ml-2 w-5 h-5" name="rating" value="good" onChange={({target})=>this.setState({rating: target.value})}/>
            </div>
            <div className="px-1 mx-2 text-2xl">
              <span aria-label="average" role="img">😐</span>
              <input required type="radio" className="ml-2 w-5 h-5" name="rating" value="average" onChange={({target})=>this.setState({rating: target.value})}/>
            </div>
            <div className="px-1 mx-2 text-2xl">
              <span aria-label="bad" role="img">😟</span>
              <input required type="radio" className="ml-2 w-5 h-5" name="rating" value="bad" onChange={({target})=>this.setState({rating: target.value})}/>
            </div>
          </div>
        
        <label htmlFor="feedback-message" className="text-brand-blue font-semibold text-sm block my-1">Feedback message</label>
        <textarea required
          onChange={({target})=>this.setState({feedback: target.value})}
          className="rounded-lg border focus:outline-none focus:border-blue-500 p-2 mb-4 block w-full h-40"
          name="feedback-message"
          id="feedback-message"
          value={feedback}
          placeholder="Give us your feedback here on how we can better improve your experience on this website"
        />
        <button className="bg-brand-blue text-white py-2 px-4 rounded-xl whitespace-nowrap flex transition-all duration-700">
          <div>Submit Feedback</div> {loading&& <div className="ml-2 inline-block h-6"><EllipsesLoader/></div>}
        </button>
      </form>
      {success &&
        <div className="bg-green-100 inline-block my-4 font-semibold p-2 text-green-700">{success}</div>
      }
      {error &&
        <div className="bg-red-100 inline-block my-4 font-semibold p-2 text-red-700">{error}</div>
      }
      </div>
    );
  }
}
