
import React from "react";
import LogoDark from '../content/images/logo_d.png';
import {Link, navigate} from 'gatsby';
import Auth from '../utils/auth';
import Notifications from './notifications/'
export default class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenHeight: null,
    }
  }
  componentDidMount() {
    this.checkScreenHeight();
    window.addEventListener('resize', this.checkScreenHeight.bind(this))
  }
  checkScreenHeight(){
    this.setState({screenHeight: window.innerHeight - 75})
  }
  render(){
    const {screenHeight} = this.state;
    return (
      <>
        <Header/>
        <Notifications/>
          <main style={{minHeight: screenHeight? screenHeight: null}}>
            {this.props.children}
          </main>
        {/* <Footer/> */}
      </>
    )
  }
}

export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      staff: false,
    }
  }
  
  componentDidMount() {
    const userInfo = Auth.getUserInfo();
    if(userInfo){
      this.setState({
        username: userInfo.username,
        staff: userInfo.role.type === 'staff',
      })
    }
  }
  logOut(){
    Auth.clearUserInfo();
    Auth.clearToken();
    navigate('/auth/');
  }
  render() {
    const {username, staff} = this.state;
    return (
      <div className="py-5 shadow-xs bg-white w-full top-0 border-b">
        <div className="container px-5">
        <div className="flex w-full items-center">
          <Link to="/" className="mr-auto" id="header-logo">
            <img src={LogoDark} alt="New_Image™ International" className="w-40" width="768" height="201"/>
          </Link>
          {username?
          <nav className="font-bold text-brand-blue flex items-center">
            {staff&&
              <span className="fi-rr-id-badge text-2xl mr-2"></span>
            }
            <Link to="/account">{username}</Link>&ensp;|&ensp;
            {staff&&
              <Link to="/managers-dashboard" className="bg-indigo-600 mx-2 px-2 py-1 text-white rounded-lg border font-medium hover:bg-brand-blue transition-all duration-500 hover:text-white">Manager Dashboard</Link>
            }
            <button id="logout-bt" className="bg-brand-blue px-2 py-1 text-white rounded-lg border font-medium hover:bg-brand-blue transition-all duration-500 hover:text-white" onClick={this.logOut.bind(this)}>Logout</button>
            
          </nav>
          :
          <nav className="font-bold text-brand-blue mr-4">
            <button className="bg-blue-100 px-2 py-1 text-blue-800 rounded-lg border font-medium hover:bg-blue-500 transition-all duration-500 hover:text-white" onClick={this.logOut.bind(this)}>Login</button>
          </nav>
          }
        </div>
        </div>
      </div>
    )
  }
}
export class Footer extends React.Component {
  render() {
    return (
      <div className="py-5">
        <div className="container">
          <img src={LogoDark} alt="New_Image™ International" className="w-20"/>
        </div>
      </div>
    )
  }
}
