import React, {Component} from 'react';
import {connect} from 'react-redux';
import {storeNotifications} from '../../redux/actions/';
function mapStateToProps(state) {
  return {notifications: state.notifications};
}

function mapDispatchToProps(dispatch) {
  return {
    storeNotifications: notifications => dispatch(storeNotifications(notifications))
  }
}

class Notifications extends Component {
  clearNotification(index) {
    let notifications = [...this.props.notifications]
    notifications.splice(index, 1);
    this
      .props
      .storeNotifications(notifications)
  }
  render() {
    const {notifications} = this.props;
    const messages = notifications.map((note, key) => <Notification
      key={key}
      index={key}
      {...note}
      clearNotification={this
      .clearNotification
      .bind(this)}/>)
    return (
      <div
        className="notifications fixed top-0 right-0 p-5 mt-20 z-50 max-w-md leading-tight ">
        {messages}
      </div>
    );
  }
}

export class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      icon: 'flaticon-rounded-info-button',
      categoryClass: 'bg-white text-indigo-700',
      clearable: true
    }
    this.timer = null;
  }
  startClearNotificationTimer() {
    this.timer = setTimeout(() => {
      this.clearNotification()
    }, 1000)
  }
  clearNotification() {
    this
      .props
      .clearNotification(this.props.index)
  }
  stopClearNotificationTimer() {
    clearTimeout(this.timer);
  }
  componentDidMount() {
    setTimeout(() => {
      this.startClearNotificationTimer()
    }, 10 * 1000)
    switch (this.props.category) {
      case 'error':
        this.setState({categoryClass: 'border border-red-200 rounded mb-2 bg-white text-red-700', icon: 'flaticon-round-error-symbol'});break;
      case 'warning':
        this.setState({categoryClass: 'border border-yellow-400 rounded mb-2 bg-yellow-200 text-yellow-700', icon: 'flaticon-round-error-symbol'});break;
      case 'success':
        this.setState({categoryClass: 'bg-white text-green-500 border border-green-200 mb-2 rounded', icon: 'flaticon-circle-with-check-symbol'});break;
      default:
        this.setState({categoryClass: 'border border rounded mb-2 bg-white text-indigo-700', icon: 'flaticon-rounded-info-button'});
    }
  }

  render() {
    const {categoryClass, icon} = this.state;
    return (
      <button
        className={`${categoryClass} shadow-md p-4 flex items-center animation-wobble text-left`}
        onMouseEnter={this
        .stopClearNotificationTimer
        .bind(this)}
        onMouseLeave={this
        .startClearNotificationTimer
        .bind(this)}>
        <span className={`${icon} text-4xl mr-3`}></span>
        {this.props.message}
        <button
          className="flaticon-clear-button ml-4 cursor-pointer"
          onClick={this
          .clearNotification
          .bind(this)}>
            <span className="hidden">Close</span>
          </button>
      </button>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
